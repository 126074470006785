.skeleton {
    width: 100%;
    // padding-bottom: 100%;
    height: 100%;
    /* Adjust aspect ratio */
    background-color: #e0e0e0;
    background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

.wdRow-cols-4 {
    .skeleton {
        height: 368px;
    }
}