
.hide {
    display: none
}

.full-blog {
    background: rgba(0, 0, 0, .8);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    display: none;
    overflow: auto
}

.sign-up {
    margin: auto;
    z-index: 99;
    position: relative;
    background: #fff;
    display: block;
    .right {
        width: 50%;
        height: 100%;
        float: right;
        position: relative;
        display: inline-block;
        min-height: 542px;
        padding: 25px 25px 0
    }
    .left {
        float: left;
        display: inline-flex;
        width: 50%;
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    
        .heading{
            h3{
               font-size: 1.125rem;
                  line-height: 22px;
                   font-family: "robotomedium";
                text-align: left;
               color: #333;
                letter-spacing: .03em;
                margin: 0px;
            }
            p {
                    color: #515151;
                    font-size: .8125rem;
                    line-height: 18px;
                    position: relative;
                    letter-spacing: 0;
                    font-family: "robotoregular";
                    margin: 3px 0 0;
            }
            strong, b{
                font-family: robotomedium;
                margin: 0 2px;
                color: #333
            }
            .editlink {
                font-size: 13px;
                text-transform: uppercase;
                color: #0095ff;
                cursor: pointer;
                &.block{
                    display: block;
                }
                
            }
        }
        .box{
            margin-top: 15px;
            position: relative;
            &:nth-last-child{
                margin: 0;
            }
            span{
                position: absolute;
                top: 12px;
                right: 12px;
                color: #333;
                background: #fff;
                padding: 0 5px;
                font-size: 13px;
                cursor: pointer;
            }
            label.error {
                display: none !important
            }
        }
   
    .forgot-pass{
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
        font-family: robotoregular;
        margin: 15px 0 0;
        color: #515151;
        float: left;
    }
    .common-btn{
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
        font-family: robotoregular;
        margin: 15px 0 0;
        color: #E27A34;
        float: right;
        text-transform: uppercase;
    }
    .signup-block{
        margin-top: 25px;
        float: left;
        width: 100%;
        p{
            font-size: 13px;
            line-height: 19px;
            color: #333;
            display: block;
            text-align: center;
            margin: 0px;
            a{
                color: #e8581a;
                margin-left: 3px;
            }
        }
    }
    .sign-btn{
        font-size: 1rem;
        background: #E27A34;
        color: #fff;
        line-height: 20px;
        display: block;
        width: 100%;
        letter-spacing: .03em;
        text-transform: uppercase;
        padding: 13px 10px;
        cursor: pointer;
        font-family: robotomedium;
        margin: 0 auto 30px;
        border-radius: 4px;
        border: 1px solid #E27A34;
        &:hover{
            background: #e8581a;
        }
        .loader{
            display: none;
            margin-right: 10px;
            vertical-align: middle;
         img {
            -webkit-animation: 2s infinite linear;
            animation: fa-spin 2s infinite linear;
            z-index: 1;
            width: 18px;
            height: 18px
        }
        
        @keyframes fa-spin {
            0% {
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg)
            }
        
            100% {
                -webkit-transform: rotate(359deg);
                -moz-transform: rotate(359deg);
                -ms-transform: rotate(359deg);
                -o-transform: rotate(359deg);
                transform: rotate(359deg)
            }
        }
    }
    }
    .social-wrap{
        border-top: 1px solid #e5e5e5;
        text-align: center;
        color: #646464;
        padding: 10px 0 15px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            display: inline-block;
            font-family: robotoregular;
            color: #515151;
            font-size: 14px;
            line-height: 18px;
            vertical-align: text-top;
            font-weight: 400;
            margin: 0px;
        }
        .btn-facebook{
            display: inline-block;
            padding: 0 0 0 10px;
            font-size: 0px;
        }
    }
    .error-block {
        padding: 0;
           font-size: 0.75rem;
    color: #e32526;
    line-height: normal;
        // display: none;
    }
    .one-half {
        margin-right: -3%;
        .box {
            width: 47%;
            float: left;
            margin-right: 4%;
        }
        .box.pincode {
            width: 46%;
            margin-right: 0;
        }
     
    }
    .clearfix{
        &:before, &:after{
            content: " ";
            display: block;
            height: 0;
            overflow: hidden;
        }
        &:after{
            clear: both;
        }
    }
    .registration-block{
        margin-top: 20px;
        .reg-text{
            font-size: 12px;
            line-height: 15px;
            color: #515151;
            margin: 0 0 12px;
            display: block;
            text-align: center;
        }
    }
    .registration-block1{
        padding-bottom: 60px;
        
    }
    .field--not-empty .field__label{
        opacity: 1;
            transform: none;
    }
    .field--not-empty {
        .field__label {
            top: -7px
        }
    }
    .resendOTP {
        text-align: right;
        margin: 10px 0 0;
      color: #0095ff;
      cursor: pointer;
    }
    .otp-timer {
        text-align: right;
        margin: 10px 0 0;
        color: #ff0000;
    }
    .next-step{
        display: none;
    }
    .signup-main{
        .next-step{
            display: block;
        }
  
     }
     .display-block-field{
            border-bottom: 1px solid red !important;
     }
    .one-half{
       .next-step{
        display: block;
    } 
    }
}

section .social-wrap {
    border-top: 1px solid #e5e5e5;
    text-align: center;
    color: #646464;
    padding: 10px 0 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      display: inline-block;
      font-family: "robotoregular";
      color: #6a6a6a;
      font-size: 14px;
      line-height:normal;
      vertical-align: text-top;
      font-weight: 400;
      margin: 0 5px;
    }
    .btn-facebook {
      display: inline-block;
      padding: 0 0 0 10px;
      margin: 0 5px 0 0;
      font-size: 0px;
      cursor: pointer;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      -khtml-border-radius: 50%;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid #e5e5e5;
      padding: 6px 8px;
    }
  }

.field__label{
    color: #4b4a4a;
    font-size: 13px;
    line-height: 1;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    padding: 0 5px;
    text-overflow: ellipsis;
    top: -5px;
    left: 10px;
    display: inline-block;
    text-align: left;
    transform: translateY(3px);
    transition: all 0.2s ease-out;
    user-select: none;
    white-space: nowrap;
    background: #fff;
    z-index: 1;
}
.field__input{
    background-color: #fff;
    border: 1px solid #acacac;
    border-radius: 2px;
    color: #4b4a4a;
    font-size: 0.8125rem;
    line-height: 24px;
    transition: all 0.2s ease-out;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    &:focus{
        border: 1px solid #515151
    }
    &::placeholder {
        color: #acacac
    }
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
}
.sign-up .error {
    display: none
}
.sign-up .cart_error .warning {
    color: red
}

.sign-up .cart_error .close {
    background: 0 0
}
.sign-up .thanku-success-msg {
    width: 100%
}

.digit-group input {
    border: 0 !important;
    border-bottom: 1px solid #acacac !important;
    border-radius: 0;
    padding: 7px;
    width: 46px !important;
    margin-right: 12px;
    text-align: center;
    font-size: 22px;
    color: #515151
}

.digit-group input:last-of-type {
    margin-right: 0
}

#loginclose {
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer
}

.swpmodal-container,
.swpmodal-overlay {
    width: 100%;
    height: 100%;
    overflow: auto
}
.sign-btn-border{
    
    font-size: 13px;
    background: #fff;
    color: #E27A34;
    line-height: 20px;
    display: block;
    width: 100%;
    text-transform: uppercase;
    padding: 13px;
    width: 100%;
    cursor: pointer;
    font-family: robotomedium;
    margin: 4px;
    border-radius: 4px;
    border: 1px solid #E27A34;
    margin-bottom: 15px;
    display: none;
    &:hover{
        color: #fff;
        background-color: #E27A34;

    }
}
.mb-35{
    margin-bottom: 35px !important;
}
.sign-or-line{
    display: none;
}
.mr-0{
    margin-right: 0px !important;
}

@media only screen and (max-width:992px) {
    .sign-up {
        background-size: 50% 100%
    }

    .sign-up .right {
        min-height: 535px
    }

 

    .digit-group input {
        width: 44px !important;
    }
}
@media(max-width:991px){
    .sign-up {
        .left{
            img{
                object-fit: cover;
            }
        }
    }

}

@media(max-width:767px){
    .sign-btn-border{
        display: block;
    }
    .sign-up{
        width: 100%;
        .common-btn{
            display: none;
        }
        .one-half{
            margin-right: 0%;
            .box{
                width: 100%;
                margin-right: 0%;
                &.pincode{
                    width: 100%;
                }
            }
        }
        .left{
            display: none;
        }
        .right{
            width: 100%;
            
            padding: 64px 20px 40px;
        }
        .box{
            margin-top: 25px;
            span{
                top: 14px;
            }
        }
        .sign-btn{
            margin-bottom: 15px;
        }
        .signup-block{
            p{
                    margin: 10px 0px 10px;
                    font-size: 14px;
            }
        }
        .sign-or-line{
            margin: 25px 0px 10px;
            position: relative;
            text-align: center;
            overflow: hidden;
            display: block;
            &:after{
                height: 1px;
                width: 100%;
                content: "";
                position: absolute;
                left: 0px;
                right: 0px;
                background: #E7E7E7;
                top: 11px;
            }
            p{
                font-size: 11px;
                line-height: 13px;
                font-weight: 400;
                color: #515151;
                margin: 0px;
                text-transform: uppercase;
                display:inline-block ;
                padding: 2px 10px;
                background-color: #fff;
                position: relative;
                z-index: 1;
            }
        }
        .social-wrap{
            position: unset;
            border: 0px;
            
            p{
                display: none;
            }
            .btn-facebook{
                padding: 10px 35px;
                border: 1px solid #CCCCCC;
                border-radius: 2px;
                text-align: center;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                color: #636572;
                text-transform: uppercase;
                margin-right: 10px;
                width: 100%;
                img{
                    margin-right: 5px;
                    width: 16px;
                }
            }
        }
    }
    .field__input{
        height: 48px;
        border-radius: 2px;
        line-height: 17px;
    }
   
}

