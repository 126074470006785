.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: #E27A34;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;

    &.loading {
        cursor: not-allowed;
    }
}

.btn:hover {
    color: #fff;
    background-color: #f15d18;
    border-color: #f15d18;
}

.btn-primary {
    color: #fff;
    background-color: #E27A34;
    border-color: #E27A34;
}

.btn-primary:hover {
    color: #fff;
    background-color: #f15d18;
    border-color: #f15d18;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

/* Add styles for other variants like success, danger, warning, info, light, and dark as needed */

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn:disabled {
    opacity: 0.65;
    pointer-events: none;
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-success:hover {
    background-color: #218838;
    border-color: #1e7e34;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    background-color: #c82333;
    border-color: #bd2130;
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning:hover {
    background-color: #e0a800;
    border-color: #d39e00;
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info:hover {
    background-color: #138496;
    border-color: #117a8b;
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:hover {
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:hover {
    background-color: #23272b;
    border-color: #1d2124;
}

.loadingSpinner {
    display: inline-block;
    width: 1em;
    height: 1em;
    border: 2px solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spin 0.75s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

// Spinner loader
.spinner {
    display: inline-block;
    width: 1em;
    height: 1em;
    border: 2px solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spin 0.75s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

// Dots loader
.dots {
    display: inline-block;
    position: relative;
    width: 3em;
    height: 1em;

    &::before,
    &::after,
    & {
        content: '';
        position: absolute;
        width: 0.8em;
        height: 0.8em;
        border-radius: 50%;
        background-color: currentColor;
        animation: dots 1s infinite ease-in-out;
    }

    &::before {
        left: -1.2em;
        animation-delay: -0.32s;
    }

    &::after {
        left: 1.2em;
        animation-delay: 0.32s;
    }
}

@keyframes dots {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

// Bar loader
.bar {
    display: inline-block;
    width: 3em;
    height: 0.3em;
    background-color: currentColor;
    animation: bar 1s infinite ease-in-out;
}

@keyframes bar {
    0% {
        transform: scaleX(0.1);
    }

    50% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0.1);
    }
}

@media screen and (max-width:767px) {
    .btn {
        display: block;
        width: 100%;
        line-height: 2;
    }
}