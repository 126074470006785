.cityslider-section {
    text-align: center;
    color: #333;
    padding: 30px 10px !important;

    .storebox {
        display: block;
        padding: 0 10px;

        img {
            border-radius: 5px;
        }
    }

    .customer_story_detail {
        padding-bottom: 20px;
        display: block;
        white-space: nowrap;
        overflow: scroll;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-right: -10px;
        .singlestore{
            display: inline-block;
            margin-right: 10px;
            padding-bottom: 10px;
            width: 100%;
            img {
                width: 100%;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .customer_detail {
        // font-size: 17px;
        // line-height: 22px;
        // text-align: left;
        // color: #333;
        // display: block;
        // font-family: 'pangrammedium';
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        display: block;
        margin: 0 auto 3px;
        font-family: "pangramregular";
        text-align: left;
    }

    .title {
        // font-size: 30px;
        // line-height: 34px;
        // font-family: 'pangrammedium';

        padding: 0 0 15px;
        display: block;
        text-align: center;
        font-size: 1.0625rem;
        line-height: 20px;
        color: #333333;
        font-family: "pangrammedium";
    }

    .distance-tag {
        display: block;
        text-align: left;
        font-family: 'pangramregular';
        color: #646464;
        margin: 2px 0;
    }

    .view_product {
        font-size: 14px;
        line-height: 18px;
        display: block;
        text-align: left;
        font-family: "pangramregular";
        color: #E27A34;
    }

    .view-all-b {
        width: 55%;
    }
    
    .view-all-b {
        background-color: transparent;
        display: block;
        padding: 8px 20px;
        color: #646464;
        border: 1px solid #646464;
        border-radius: 4px;
        text-align: center;
        margin: 0px auto 0;
        width: 45%;
        font-family: "pangramregular";
        letter-spacing: 0.01em;
        // background: #434343;
        width: 55%;

        .arrow-icon {
            background: url(/mobile/images-m/category-new/ArrowRight.svg) no-repeat;
            display: inline-block;
            width: 18px;
            height: 20px;
            vertical-align: middle;
            margin-left: 6px;
        }
    }
}


@media (max-width: 768px) {

    .cityslider-section
    .customer_story_detail img {
        width: 294px;
        min-width: 294px;
        max-width: 294px;
        height: auto;    
    }

}